import { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useParams } from 'react-router-dom'

import styles from './GameWindow.module.scss'
import { useHandlerIframe } from '@/entities/game/hooks'
import { useGameSession } from '@/features/games/games-list/api'
import { useGetScreenResolution } from '@/shared/hooks/useGetScreenResolution'
import { useAppSelector } from '@/shared/model'
import { Loader } from '@/shared/ui/loader/Loader'

export const GameWindow = () => {
	const { locale } = useAppSelector(state => state.defaultReducer)
	const { refFrame } = useHandlerIframe()
	const { game_id } = useParams()
	const [heightIframe, setHeightIframe] = useState<number>()
	const { width } = useGetScreenResolution()

	const { data, isLoading, isError } = useGameSession({
		game_id: Number(game_id),
		lang: locale.toUpperCase()
	})

	const iframeElement = refFrame.current

	const getHeaderHeight = () => 0 // Совокупная высота элементов расположеных выше iframe
	const getDefaultIFrameHeight = () =>
		globalThis.innerHeight - getHeaderHeight()
	const getScrollTop = () => (window.scrollY >= 0 ? window.scrollY : 0)
	const sendPageAttributes = () => {
		iframeElement?.contentWindow?.postMessage({
			type: 'GPWebAppSetPageAttributes',
			data: {
				scrollX: document.documentElement.scrollLeft,
				scrollY: getScrollTop(),
				clientHeight: document.documentElement.clientHeight,
				clientWidth: document.documentElement.clientWidth,
				scrollFromTop: getScrollTop(),
				availableFrameHeight: getDefaultIFrameHeight()
			}
		})
	}

	const updateFrameHeight = (withScroll = false) => {
		const iframeHeight = getDefaultIFrameHeight()
		const updateIframeHeight = withScroll
			? String(getScrollTop() + iframeHeight)
			: String(iframeHeight)
		if (iframeElement) {
			iframeElement.height = updateIframeHeight
		}
		setHeightIframe(Number(updateIframeHeight))

		sendPageAttributes()
	}

	const listenerMessage = (event: MessageEvent<any>) => {
		const { channel } = event.data

		if (channel === 'GPWebAppBridge') {
			const { type, data } = event.data

			if (type === 'GPWebAppInit') {
				console.log('xxxxx')

				iframeElement?.contentWindow?.postMessage(
					{
						type: 'SetSupportedMethods',
						data: ['GPWebAppSetIframeHeight']
					},
					'*'
				)

				updateFrameHeight()

				iframeElement?.contentWindow?.postMessage(
					{
						type: 'GPWebAppInitResult',
						data: {
							result: true,
							requestId: data.requestId
						}
					},
					'*'
				)
			} else if (type === 'GPWebAppSetIframeHeight') {
				console.log('yyyy')

				if (data.height === 0) {
					updateFrameHeight(true)
				} else if (data.height > 0 && iframeElement) {
					iframeElement.height = data.height
					sendPageAttributes()
				}

				console.log('zzzz')

				iframeElement?.contentWindow?.postMessage(
					{
						type: 'GPWebAppSetIframeHeightResult',
						data: {
							result: true,
							requestId: data.requestId
						}
					},
					'*'
				)
			}
		}
	}

	useEffect(() => {
		window.addEventListener('message', listenerMessage)
		if (iframeElement) {
			window.scrollTo(0, 0)
		}
		return () => {
			window.removeEventListener('message', listenerMessage)
		}
	}, [iframeElement, width])

	return (
		<div className={styles.wrapper}>
			{isLoading ? (
				<Loader />
			) : isError ? (
				<FormattedMessage id='error' />
			) : !data?.uri ? (
				<FormattedMessage id='not_found' />
			) : null}
			{!isError && (
				<iframe
					style={{ height: heightIframe }}
					ref={refFrame}
					width={'100%'}
					frameBorder='0'
					src={data?.uri}
					title='game'
				/>
			)}
		</div>
	)
}
